export default {
    categories: [
        {
            icon:"home",
            key:"home",
            route:"home",
            title:'Ana Sayfa' 
        },
        {
            icon:"settings",
            key:"system-management",
            title:'Sistem Yönetimi' 
        },
        {
            icon:"groups",
            key:"cari",
            route:"cari",
            title:'Cari İşlemleri' 
        },
        {
            icon:"local_offer",
            key:"offer-operations",
            title:'Teklif İşlemleri' 
        },
        {
            icon:"handshake",
            key:"agreement",
            title:'Sözleşme İşlemleri' 
        },
        {
            icon:"route",
            key:"voyage",
            title:'Sefer İşlemleri' 
        },
        {
            icon:"fact_check",
            key:"waybill-group",
            title:'İrsaliye İşlemleri' 
        },
        {
            icon:"receipt",
            key:"receipt-operation",
            route:"receipt-operation",
            title:'Fatura İşlemleri' 
        },
    ],
    items:[
        {
            category:'waybill-group',
            icon:'fact_check',
            route:'waybill-operations',
            title:'Müşteri-İrsaliye İşlemleri'
        },
        {
            category:'waybill-group',
            icon:'fact_check',
            route:'receipt-waybill-operations',
            title:'Alış İrsaliyesi İşlemleri'
        },
        {
            category:'system-management',
            icon:'people',
            route:'user',
            title:'Kullanıcılar'
        },
        {
            category:'system-management',
            icon:'settings_accessibility',
            route:'role',
            title:'Rol Yönetimi'
        },
        {
            category:'system-management',
            icon:'dns',
            route:'system-parameter',
            title:'Sistem Parametreleri'
        },
        {
            category:'system-management',
            icon:'dns',
            route:'transfer-region',
            title:'Transfer Bölgesi İşlemleri'
        },
        {
            category:'system-management',
            icon:'shopping_basket',
            route:'material',
            title:'Ürün İşlemleri'
        },
        {
            category:'system-management',
            icon:'local_shipping',
            route:'vehicle-type',
            title:'Araç Tipi İşlemleri'
        },
        {
            category:'offer-operations',
            title:'Teklifler',
            icon:'quiz',
            route:'offer-request'
        },
        // {
        //     category:'system-management',
        //     icon:'edit_location',
        //     route:'location-type',
        //     title:'Lokasyon Tipi İşlemleri'
        // },
        {
            category:'voyage',
            route:"voyage",
            title:'Seferler',
            icon:'route'
        },
        {
            category:'voyage',
            route:"voyage-report",
            title:'Sefer Raporu',
            icon:'fork_left'
        },
        {
            category:'agreement',
            route:"agreement",
            title:'Sözleşmeler',
            icon:"handshake",
        },
        {
            category:'agreement',
            route:"out-agreement",
            title:'Dış Sözleşmeler',
            icon:"handshake",
        }
    ]
}
