<template>
    <li class="nav-menu-item">
        <div class="nav-menu-item-text" v-on:click="parentClick(item)" v-on:contextmenu="parentRightClick($event,item)" :title="$safe(item,'route') ? getTitle(item) : null">
            <div class="d-flex align-items-center" :class="item.active ? 'active':null" >
                <span v-if="item.icon" class="material-icons-outlined icon" >{{item.icon}}</span>
                <span>{{item.title}}</span>
            </div>
            <span v-if="item.children" class="material-icons-outlined chevron" :class="!isCollapsed ? 'collapsed':''">expand_more</span>
        </div>
        <b-collapse v-if="item.children" v-model="isCollapsed">
            <ul class="sub-nav-menu-items" v-if="item.children">
                <li class="sub-nav-menu-item" v-for="(child,c_index) in item.children" :class="child.active ? 'active':null" :key="'c-' + c_index + toKey(child)" v-on:click="childClick(child)" v-on:contextmenu="childRightClick($event,child)" :title="$safe(child,'route') ? getTitle(child) : null">
                    <span class="material-icons-outlined">{{child.icon}}</span>
                    {{child.title}}
                </li>
            </ul> 
        </b-collapse>
    </li> 
</template>

<script>
import {BCollapse} from 'bootstrap-vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import Additional from '@/utilities/additional'
export default {
    components:{
        BCollapse
    },
    props:{
        item:Object
    },
    data(){
        return {
            isCollapsed:false
        }
    },
    watch:{
        "item.active":{
            handler(newVal,oldVal){
                if(newVal){
                    this.isCollapsed = true
                }
            },
            immediate:true
        }
    },
    methods:{
        parentClick(item){
            if(item.route && this.$route.name != item.route){
                this.$router.push({name:item.route})
                useVerticalNavMenu().collapse(true)
            }else{
                this.isCollapsed = !this.isCollapsed
            }
        },
        childClick(child){
            if(child.route && this.$route.name != child.route){
                this.$router.push({name:child.route})
                useVerticalNavMenu().collapse(true)
            }
        },
        parentRightClick(event,item){
            event.preventDefault()
            if(item.route){
                let routeData = this.$router.resolve({name: item.route})
                window.open(routeData.href, '_blank')
            }
        },
        childRightClick(event,child){
            event.preventDefault()
            if(child.route){
                let routeData = this.$router.resolve({name: child.route})
                window.open(routeData.href, '_blank')
            }
        },
        getTitle(item){
            return [item.title,"Sağ tıklayarak yeni sekmede açabilirsiniz."].join(" - ")
        },
        toKey(item){
            return Additional.ToEng(item.title).toLowerCase().replace(/\s/g, "")
        }
    },
    computed:{
        isVerticalMenuCollapsed(){
            return useVerticalNavMenu().isVerticalMenuCollapsed
        },
    }
}
</script>

<style scoped>
    .nav-menu-item-text{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(0, 0, 0, 0.75);
        cursor: pointer;
        font-size: 0.95rem;
    }
    .nav-menu-item-text:hover{
        color: rgba(0, 0, 0, 1);
    }

    .dark-layout .nav-menu-item-text{
        color: rgba(255, 255, 255, 0.80);
    }

    .dark-layout .nav-menu-item-text:hover{
        color: rgba(255, 255, 255, 1);
    }
    
    .nav-menu-item-text .icon{
        font-size: 1.4rem;
        color: rgba(0, 0, 0, 0.5);
        margin-right: 10px;
    }

    .dark-layout .nav-menu-item-text .icon{
        color: rgba(255, 255, 255, 0.5);
    }

    .nav-menu-item-text .chevron{
        transition: all .2s linear;
        color: rgba(0, 0, 0, 0.5);
    }

    .dark-layout .nav-menu-item-text .chevron{
        color: rgba(255, 255, 255, 0.5);
    }

    .nav-menu-item-text .chevron.collapsed{
        transform: rotate(-90deg);
    }

    .nav-menu-item-text .active,
    .nav-menu-item-text .active span.icon{
        color:var(--primary) !important;
        font-weight: 600;
    }

    .dark-layout .nav-menu-item-text .active,
    .dark-layout .nav-menu-item-text .active span.icon{
        color:rgba(255, 255, 255, 1) !important;
    }

    .sub-nav-menu-items{
        list-style-type: none;
        margin: 0px;
        padding: 0.25rem 0.5rem 0.25rem 0.5rem
    }

    .sub-nav-menu-item{
        color: rgba(0, 0, 0, 0.65);
        display: flex;
        align-items: center;
        margin-top: 0.75rem;
        cursor: pointer;
        font-size: 0.93rem;
    }
    .dark-layout .sub-nav-menu-item{
        color: rgba(255, 255, 255, 0.55);
    }   

    .sub-nav-menu-item.active{
        color:var(--primary);
        font-weight: 600;
    }

    .dark-layout .sub-nav-menu-item.active{
        color: rgba(255, 255, 255, 1);
    }

    .sub-nav-menu-item span{
        font-size: 1.4rem;
        width: 20px;
        margin-right: 0.5rem;
    }
    .sub-nav-menu-item:not(.active):hover{
        color: rgba(0, 0, 0, 1);
    }
    .dark-layout .sub-nav-menu-item:hover{
        color: rgba(255, 255, 255, 1);
    }
</style>