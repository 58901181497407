<template>
    <b-dropdown boundary="viewport" class="dropdown-notification" menu-class="dropdown-menu-media" dropright no-caret toggle-tag="div" toggle-class="user-notifications">  
        <template #button-content>
            <div class="notifications-wrapper">
                <b-badge variant="success" pill v-if="count">{{count}}</b-badge>
                <span class="material-icons-round">notifications</span>
            </div>
        </template>

        <li class="dropdown-menu-header border-bottom">
            <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">Bildirimler</h4>
                <b-badge pill variant="light-primary" v-if="count">{{count}} Yeni</b-badge>
            </div>
        </li>

        <vue-perfect-scrollbar v-if="count" v-once :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area" tagname="li">
            <template v-for="(notification,index) in nots">
                <b-link  :key="notification.Id+index">
                    <b-media class="py-50">
                        <template #aside>
                            <b-avatar size="32" :variant="notification.Color" class="avatar-icon">
                                <span class="material-icons-round">{{notification.Icon}}</span>
                            </b-avatar>
                        </template>
                        <p class="media-heading text-dark mb-0 pb-25">
                            <span class="font-weight-bolder">{{ notification.Title }}</span>
                        </p>
                        <small class="notification-text text-muted">{{ notification.Content }}</small>
                    </b-media>
                </b-link>
            </template>
        </vue-perfect-scrollbar>

        <li v-if="count == 0" class="text-center mt-1">
            Bildirim bulunmamaktadır.
        </li>

        <li class="dropdown-menu-footer px-1 pb-50 mt-1"><b-button variant="primary" block >Daha fazla bildirim</b-button></li>
    </b-dropdown>
</template>

<script>
import { BDropdown,BBadge,BDropdownItem,BButton,BLink,BMedia,BAvatar } from 'bootstrap-vue'
import Notifications from '@/utilities/notifications'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
    components:{
        BDropdown,
        BBadge,
        BDropdownItem,
        BButton,
        BLink,
        BMedia,
        VuePerfectScrollbar,
        BAvatar
    },
    data(){
        return{
            perfectScrollbarSettings:{
                maxScrollbarLength: 60,
                wheelPropagation: false,
            }
        }
    },
    computed:{
        nots(){
            return Notifications.notifications
        },
        count(){
            if(this.nots?.length){
                return this.nots.length
            }else return 0
        }
    }
}
</script>

<style>
.vertical-nav-menu .user-notifications{
    background-color: transparent !important;
    border: 0px !important;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 40px;
    box-shadow: none !important;
}

.vertical-nav-menu .user-notifications .notifications-wrapper{
    position: relative;

}

.vertical-nav-menu .user-notifications .notifications-wrapper .badge{
    position: absolute;
    top: -10px;
    right: -10px;
    
}

.vertical-nav-menu .user-notifications .notifications-wrapper .badge{
    position: absolute;
    top: -10px;
    right: -10px;
    
}

.dropdown-menu-media{
    width: 280px;
}

.media-list{
    max-height: 300px;
}

.media-list a .avatar-icon span.material-icons-round{
    font-size: 20px;
}

</style>