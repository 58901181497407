<template>
    <div class="vertical-nav-menu">
        <transition name="slide">
            <div v-if="isVerticalMenuActive" class="left-side">
                <div class="categories">
                    <div class="brand-logo">
                        <img :src="appLogoImage" alt="logo" />
                    </div>
                    <vue-perfect-scrollbar>
                        <ul class="left-side-menu">
                            <li v-b-tooltip.noninteractive.hover.bottomright :title="item.title" class="category" :class="item.key == activeCategory ? 'active' : null" v-for="(item,index) in categories" :key="index" v-on:click="categoryClick(item)" v-on:contextmenu="onRightClick(item)">
                                <template >
                                    <transition name="fade">
                                        <span v-if="item.key == activeCategory" class="indicator"></span>
                                    </transition>
                                    <span class="material-icons-outlined">{{item.icon}}</span>
                                    <transition name="fade">
                                        <span v-if="looking == item.key && items.length && !isVerticalMenuCollapsed" class="material-icons-outlined chevron">chevron_right</span>
                                    </transition>
                                </template>
                            </li>
                        </ul>
                    </vue-perfect-scrollbar>
                </div>
                <div class="actions">
                    <!-- <new-vertical-nav-menu-notifications></new-vertical-nav-menu-notifications> -->
                    <new-vertical-nav-menu-user-actions></new-vertical-nav-menu-user-actions>
                </div>
            </div>
        </transition>
        <transition name="slide">
            <div v-if="!isVerticalMenuCollapsed && items.length" class="right-side">
                <div class="panel-title text-nowrap">
                    {{ categoryTitle }}
                    <span class="material-icons-round mr-2 text-primary nav-menu-closer rounded" v-on:click="collapse">menu_open</span>
                </div>
                <vue-perfect-scrollbar class="right-side-inside" tagname="div" >
                    <ul class="nav-menu-items">
                        <template v-for="(item,index) in items">
                            <new-vertical-nav-menu-item :item="item" :key="index + toKey(item)" :class="index == items.length - 1 ? 'pb-1':null"></new-vertical-nav-menu-item>
                            <div v-if="index != items.length - 1" class="nav-menu-divider" :key="'divider-' + index"></div>
                        </template>
                    </ul>
                </vue-perfect-scrollbar>
            </div>
        </transition>
    </div>
</template>

<script>
import useVerticalNavMenu from './useVerticalNavMenu'
import NewVerticalNavMenuItem from './NewVerticalNavMenuItem'
import navigation from '@/navigation/vertical/new-index'
import { $themeConfig } from '@themeConfig'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import NewVerticalNavMenuUserActions from './NewVerticalNavMenuUserActions'
import NewVerticalNavMenuNotifications from './NewVerticalNavMenuNotifications'
import { VBTooltip } from 'bootstrap-vue'
import Auth from '@/utilities/auth'
import Additional from '@/utilities/additional'

export default {
    components:{
        NewVerticalNavMenuItem,
        VuePerfectScrollbar,
        NewVerticalNavMenuUserActions,
        NewVerticalNavMenuNotifications
    },
    directives:{
        'b-tooltip':VBTooltip
    },
    props:{
        isVerticalMenuActive: {
            type: Boolean,
            required: true,
        },
    },
    data(){
        return{
            activeCategory: null,
            looking:null
        }
    },
    watch:{
        "$route.name":{
            handler(newVal,oldVal){
                this.configureRoute()
                this.controlItems()
            },
            immediate:true
        },
    },
    methods: {
        categoryClick(item){
            if(item?.route){
                if(this.$route.name != item.route){
                    this.$router.push({name:item.route})
                }
                this.looking = item.key
                this.controlItems()
            }else{
                if(item.key == this.looking){
                    useVerticalNavMenu().toggleCollapsed()
                }else{
                    useVerticalNavMenu().collapse(false)
                    this.looking = item.key
                }
            }
        },
        onRightClick(item){
            if(item?.route){
                let routeData = this.$router.resolve({name: item.route})
                window.open(routeData.href, '_blank')
            }
        },
        controlItems(){
            if(this.items.length == 0){
                    useVerticalNavMenu().collapse(true)
            }
        },
        collapse(){
            if(this.isMobile){
                useVerticalNavMenu().isVerticalMenuActive.value = false
            }
            useVerticalNavMenu().collapse(true)
        },
        itemClick(item){
            if(item.route && item.route != this.$route.name){
                this.$router.push({name:item.route})
            }
        },
        itemActive(item){
            return item.active ? 'active' : ''
        },
        configureRoute(){
            navigation.categories.forEach(c=>{ c.active = false })
            this.allItems.forEach(item=>{ 
                item.active = false 
                item.children?.forEach(c=>{ c.active = false })
            })
            this.activeCategory = null
            this.looking = null

            //IF CATEGORY
            let category = navigation.categories.find(c=> c?.route && c.route == this.$route.name)
            if(category) {
                this.$set(category,'active',true)
                this.activeCategory = category.key
                this.looking = category.key
            }

            //IF ITEM
            let item = this.allItems.find(item=>{
                return (item?.route && item.route == this.$route.name) ? true : false || item?.children?.some(c=>c.route == this.$route.name)
            })
            if(item){
                this.activeCategory = item.category
                this.looking = item.category
                this.$set(item,'active',true)
                let child = item?.children?.find(c=>c.route == this.$route.name)
                if(child) this.$set(child,'active',true)
            }
        },
        toKey(item){
            return Additional.ToEng(item.title).toLowerCase().replace(/\s/g, "")
        }
    },
    computed:{
        isVerticalMenuCollapsed(){
            return useVerticalNavMenu().isVerticalMenuCollapsed.value
        },
        isMobile(){
            return useVerticalNavMenu().isMobile.value
        },
        categories(){
            let categories = navigation.categories
            this.itemsCategories = this.allItems.map(ai=>ai.category)
            return categories.filter(c=>{
                if(c.route) {
                    let roles = this.$router.options.routes.find(r=>r.name == c.route)?.meta?.requiresRole || []
                    if(roles.length){
                        if(Auth.hasRole(roles)) return true
                        else false
                    }
                    else return true
                }
                else return this.itemsCategories.includes(c.key)
            })
        },
        allItems(){
            let items = navigation.items
            items.forEach(item=>{
                if(item.children){
                    item.children.forEach(i=>{
                        let roles = this.$router.options.routes.find(r=>r.name == i.route)?.meta?.requiresRole || []
                        if(roles.length){ i.visible = Auth.hasRole(roles)}
                        else i.visible = true
                    })

                    if(item.children.length == item.children.filter(i=>!i.visible).length){ item.visible = false}
                    else{item.visible = true}

                }else{
                    let roles = this.$router.options.routes.find(r=>r.name == item.route)?.meta?.requiresRole || []
                    if(roles.length){
                        item.visible = Auth.hasRole(roles)
                    }else item.visible = true
                }
            })
            return items.map(i=>{ return {...i,children:i.children?.filter(c=>c.visible)} }).filter(i=> i.visible)
        },
        items(){
            return this.allItems.filter(item=> item.category == this.looking)
        },
        categoryTitle(){
            let category = navigation.categories.find(c=> c.key == this.looking)
            return category?.title
        },
        nav(){ return navigation },
        appLogoImage(){
            return $themeConfig.app.appLogoImage
        }
    }
}
</script>

<style>
    .vertical-nav-menu {
        width: 320px;
        position: fixed;
        z-index: 1000;
    }

    .brand-logo{
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .brand-logo img{
        height: 1.5rem
    }

    .nav-menu-divider{
        height: 1px;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        background-color: rgba(0,0,0,0.05);
    }

    .dark-layout .nav-menu-divider{
        background-color: rgba(255,255,255,0.2);
    }
    
    .left-side {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 80px;
        background-color: var(--primary);
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .right-side {
        position: fixed;
        padding: 0 0 0 1.35rem;
        top: 0;
        left: 80px;
        height: 100vh;
        width: 240px;
        z-index: 1;
        background-color: #fff;
        box-shadow:4px 0px 24px 0 rgb(34 41 47 / 5%)
    }

    .dark-layout .right-side{
        background-color: hsl(222, 47%, 20%) !important;
    }

    .right-side .right-side-inside{
        padding-right: 1.25rem;
        height: calc(100vh - (60px + 1.25rem));
    }

    .left-side ul.left-side-menu{
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        height: calc(100vh - 200px);
    }

    .left-side ul.left-side-menu li.category{
        width: 80px;
        display: flex;
        justify-content: center;
        padding: 1rem 0rem;
        cursor: pointer;
        position: relative;
        color: rgba(255,255,255,0.6);
    }


    .left-side ul.left-side-menu li.category.active{
        color:white
    }

    .left-side ul.left-side-menu li.category .chevron{
        position: absolute;
        right: 0.75rem;
        top:50%;
        transform: translateY(-50%);
        font-size: 1rem;
        color: white !important;
    }

    .left-side ul.left-side-menu li.category .indicator{
        position: absolute;
        left: 1rem;
        height: 1rem;
        top:50%;
        transform: translateY(-50%);
        width: 3px;
        border-radius: 3px;
        background-color: rgba(255,255,255,0.85) !important;
    }

    .right-side .panel-title{
        height: 60px;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 1.25rem;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        margin-bottom: 1.25rem;
    }

    .dark-layout .right-side .panel-title{
        color:rgba(255,255,255,0.85);
    }


    .nav-menu-closer{
        width: 2.4rem;
        height: 2.4rem;
        background-color: white;
        position: absolute;
        right: -2.7rem;
        box-shadow:0px 4px 24px 0 rgb(34 41 47 / 10%);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: box-shadow 0.3s ease-in-out;
        cursor: pointer;
    }

    .dark-layout .nav-menu-closer{
        background-color: hsl(222, 47%, 25%) !important;
        color: white !important;
    }

    .nav-menu-closer:hover{
        box-shadow:0px 4px 24px 0 rgb(34 41 47 / 25%);
    }

    .nav-menu-items{
        list-style-type: none;
        padding: 0px;
        margin: 0px;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .slide-enter-active,
    .slide-leave-active {
        transition: all 300ms ease;
    }
    .slide-enter,
    .slide-leave-to {
        transform: translateX(-150%);
    }

    .ps__rail-y{
        background-color: transparent !important;
    }

</style>