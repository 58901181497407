<template>
    <b-dropdown boundary="viewport" dropright no-caret toggle-tag="div" toggle-class="user-actions">
        <template #button-content>
            <b-avatar size="40" variant="light-primary" badge :src="require('@/assets/images/avatars/no-user-pic.svg')" class="badge-minimal" badge-variant="success"/>
        </template>

        <b-dropdown-item v-on:click="dark" link-class="d-flex align-items-center">
            <feather-icon size="16" :icon="`${isDark ? 'Sun' : 'Moon'}Icon`" class="mr-50" />
            <span>{{isDark ? 'Gece Modu (Açık)' : 'Gece Modu (Kapalı)' }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-header>{{fullName}} <span class="text-muted">#{{email}}</span></b-dropdown-header>

        <b-dropdown-item :to="{ name: 'profile'}" link-class="d-flex align-items-center">
            <feather-icon size="16" icon="UserIcon" class="mr-50"/>
            <span>Profil</span>
        </b-dropdown-item>

        <b-dropdown-item v-on:click="logout" variant="danger" link-class="d-flex align-items-center">
            <feather-icon size="16" icon="LogOutIcon" class="mr-50"/>
            <span>Çıkış Yap</span>
        </b-dropdown-item>

    </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem,BAvatar,BDropdownDivider,BDropdownHeader } from 'bootstrap-vue'
import Auth from '@/utilities/auth'
import System from '@/utilities/system'
import useAppConfig from '@core/app-config/useAppConfig'
export default {
    components:{
        BDropdown,
        BDropdownItem,
        BAvatar,
        BDropdownDivider,
        BDropdownHeader,
    },
    computed:{
        fullName(){
            return [Auth.user.FullName].join(' ')
        },
        email(){
            return Auth.user.Email
        },
        role(){
            return Auth.role
        },
        isDark(){
            const { skin } = useAppConfig()
            return skin.value === 'dark' ? true : false
        },
    },
    methods:{
        logout() {
            Auth.isAuthenticated = false
            Auth.user = {}
            this.$router.push({ name: 'login' })
        },
        dark(){
            const { skin } = useAppConfig()
            skin.value = this.isDark ? 'light' : 'dark'
        },
        safe(){
            System.GStatus = !System.GStatus
        }
    }
}
</script>

<style>
    .vertical-nav-menu .user-actions{
        background-color: transparent !important;
        border: 0px !important;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        box-shadow: none !important;
    }
</style>